<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title"> <i class="fa fa-map-marker mt-1 mr-2" aria-hidden="true" style="font-size: 18px; margin-right: 3px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="userbilldetailsAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="userbilldetailsObjList && userbilldetailsObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="userbilldetailsObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn">
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(bill_address)="data">
                    {{ data.item.bill_address }} <br>
                    <b-button class="actionIcon btn mr-1 mb-1" size="sm" @click="userbilldetailsEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button class="actionIcon btn mr-1 mb-1" @click="showUserBillDetailsDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelUserBillDetailsAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <UserBillDetailsAdd :propOpenedInModal="true" @emitCloseUserBillDetailsAddModal="closeUserBillDetailsAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="userbilldetailsAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeUserBillDetailsAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelUserBillDetailsEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <UserBillDetailsEdit :propOpenedInModal="true" :propUserBillDetailsObj="userbilldetailsEditObj" @emitCloseUserBillDetailsEditModal="closeUserBillDetailsEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="userbilldetailsEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeUserBillDetailsEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelUserBillDetailsDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelUserBillDetailsDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="userbilldetailsDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { UserBillDetails } from "../../../FackApi/api/UserBillDetails.js"
import UserBillDetailsAdd from "./UserBillDetailsAdd"
import UserBillDetailsEdit from "./UserBillDetailsEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "UserBillDetailsList",
  components: {
    UserBillDetailsAdd,
    UserBillDetailsEdit
  },
  data () {
    return {
      cvCardTitle: "User Billing Address",
      cvbtnAddNew: "Add New",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit User Billing Address",
      cvAddModalHeader: "Add User Billing Address",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "UserBillDetails",
      showModelUserBillDetailsAdd: false,
      showModelUserBillDetailsEdit: false,
      showModelUserBillDetailsDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "name", key: "bill_name", class: "text-left align-text-top", sortable: true },
        { label: "address", key: "bill_address", class: "text-left align-text-top w-200px", sortable: true },
        { label: "city", key: "bill_city", class: "text-left align-text-top", sortable: true },
        { label: "state", key: "bill_state", class: "text-left align-text-top", sortable: true },
        { label: "country", key: "bill_country", class: "text-left align-text-top", sortable: true },
        { label: "zip code", key: "bill_zip_code", class: "text-left align-text-top", sortable: true }
      ],
      userbilldetailsObjList: [],
      userbilldetailsEditObj: null,
      filter: null,
      filterOn: []
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    socialvue.index()
    this.userbilldetailsList()
  },
  methods: {
    /**
     * userbilldetailsList
     */
    async userbilldetailsList (loadViaLoadMore = false) {
      try {
        let userbilldetailsListResp = await UserBillDetails.UserBillDetailsList(this, null, this.whereFilter)
        if (userbilldetailsListResp.resp_status) {
          this.userbilldetailsObjList = [ ...this.userbilldetailsObjList, ...userbilldetailsListResp.resp_data.data ]
          // Adding the serial numbers:
          this.userbilldetailsObjList.forEach((s, index) => { s.id = index + 1 })
        }
        else {
          this.toastMsg = userbilldetailsListResp.resp_msg
          this.toastVariant = "danger"
        }
      }
      catch (err) {
        console.error("Exception occurred at userbilldetailsList() and Exception:", err)
      }
    },
    /**
     * userbilldetailsAdd
     */
    userbilldetailsAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/userbilldetails_add")
        }
        else {
          this.showModelUserBillDetailsAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userbilldetailsAdd() and Exception:", err.message)
      }
    },
    /**
     * userbilldetailsEdit
     */
    userbilldetailsEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/userbilldetails_edit/" + this.userbilldetailsEditObj.ubd_id)
        }
        else {
          this.userbilldetailsEditObj = item
          this.showModelUserBillDetailsEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userbilldetailsEdit() and Exception:", err.message)
      }
    },
    /**
     * showUserBillDetailsDeleteDialog
     */
    showUserBillDetailsDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelUserBillDetailsDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showUserBillDetailsDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * userbilldetailsDelete
     */
    async userbilldetailsDelete () {
      try {
        let userbilldetailsDelResp = await UserBillDetails.UserBillDetailsDelete(this, this.delObj.bill_id)
        await ApiResponse.responseMessageDisplay(this, userbilldetailsDelResp)

        if (userbilldetailsDelResp && !userbilldetailsDelResp) {
          this.showModelUserBillDetailsDelete = false
          return false
        }

        let index = this.userbilldetailsObjList.indexOf(this.delObj)
        this.userbilldetailsObjList.splice(index, 1)
        this.showModelUserBillDetailsDelete = false
      }
      catch (err) {
        console.error("Exception occurred at userbilldetailsDelete() and Exception:", err.message)
      }
    },
    /**
     * closeUserBillDetailsAddModal
     */
    closeUserBillDetailsAddModal (userbilldetailsAddData) {
      try {
        if (userbilldetailsAddData) {
          if (this.userbilldetailsObjList && this.userbilldetailsObjList.length >= 1) {
            let userbilldetailsObjLength = this.userbilldetailsObjList.length
            let lastId = this.userbilldetailsObjList[userbilldetailsObjLength - 1]["id"]
            userbilldetailsAddData.id = lastId + 1
          }
          else {
            this.userbilldetailsObjList = []
            userbilldetailsAddData.id = 11111
          }

          userbilldetailsAddData.created_on = moment()
          this.userbilldetailsObjList.unshift(userbilldetailsAddData)
        }

        this.showModelUserBillDetailsAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeuserbilldetailsAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeUserBillDetailsEditModal
     */
    closeUserBillDetailsEditModal () {
      try {
        this.showModelUserBillDetailsEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeUserBillDetailsEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
<style>
button.btn.actionIcon{
  font-size: 20px !important;
}
</style>
